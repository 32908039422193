import React from 'react';
import Tours from '../Components/Tours';
import data from '../Data/toursdata.json';
import LanguageSwitcher from '../Components/LanguageSwitcher';
import MainMenu from '../Components/MainMenu';

const tours = () => {
    return (
        
        <>
            <header class="section page-header">
                <MainMenu/>
                <br/><br/>
            </header>
            <Tours data={data}/>   
             
        </>
    );
}
export default tours;
