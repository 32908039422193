import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainMenu from '../Components/MainMenu';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
  return (
    <div>
    <header class="section page-header">
        <MainMenu/>
        <br/><br/>
    </header>
    <div>
    <br/>
    <h2 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('About')}</span></h2>
    <br/>
    </div>
    <div>
        <img src="images/About/About.jpeg" alt="About Us"></img>
    </div>
    <div>
        <br />
        <h3 className="text-center">Tour all over Lebanon</h3>
        <section className="section section-sm section-first bg-default">
          <div className="container">
            <div className="row row-30 justify-content-center">
              {/* Add more decorative styles as needed */}
              <div className="col-sm-8 col-md-6 col-lg-4">
                <article className="box-contacts">
                  <div className="box-contacts-body">
                    <div className="box-contacts-icon fl-bigmug-line-cellphone55"></div>
                    <div className="box-contacts-decor"></div>
                    <p className="box-contacts-link">
                      <a href="tel:+96171406376">+961 71 406 376</a>
                    </p>
                    <p className="box-contacts-link">
                      <a href="tel:+96176672377">+961 76 672 377</a>
                    </p>
                  </div>
                </article>
              </div>
              <div className="col-sm-8 col-md-6 col-lg-4">
                <article className="box-contacts">
                  <div className="box-contacts-body">
                    <div className="box-contacts-icon fl-bigmug-line-up104"></div>
                    <div className="box-contacts-decor"></div>
                    <p className="box-contacts-link">
                      <a href="https://g.co/kgs/ZNvcuXG">{t('Address1')}</a><br/>
                      <a href="https://maps.app.goo.gl/mYpGjKpyPHijJhre9">{t('Address2')}</a>
                    </p>
                  </div>
                </article>
              </div>
              <div className="col-sm-8 col-md-6 col-lg-4">
                <article className="box-contacts">
                  <div className="box-contacts-body">
                    <div className="box-contacts-icon fl-bigmug-line-chat55"></div>
                    <div className="box-contacts-decor"></div>
                    <p className="box-contacts-link">
                      <a href="mailto:Touralloverlebanon@gmail.com">Touralloverlebanon@gmail.com</a>
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <br/>
           
            <div className="accordion" id="accordionExample">
                
                {/* Office Section */}
                <div className="card">
                <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        <span className="decorated-text">{t('OfficeTitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <p>{t('OfficeText')}</p>
                    {/* Add more content as needed */}
                    </div>
                </div>
                </div>

                {/* Office Section */}
                <div className="card">
                <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                    >
                        <span className="decorated-text">{t('VipTitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseTwo"
                    className="collapse show"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/main/cars.jpg" alt="VIP Cars" className="img-fluid" ></img>
                        </div>
                        <div className="col-md-6">
                            <p>{t('VipText')}</p>
                        </div>
                        {/* Add more content as needed */}
                    </div>
                    </div>
                </div>
                </div>

                {/* Logistics Delivery Section */}
                <div className="card">
                <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                    >
                        <span className="decorated-text">{t('LogisticDTitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseThree"
                    className="collapse show"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/Logistics/LogisticD.jpeg" alt="Logistics Delivery" className="img-fluid" ></img>
                        </div>
                        <div className="col-md-6">
                            <p>{t('LogisticDText')}</p>
                        </div>
                        {/* Add more content as needed */}
                    </div>
                    </div>
                </div>
                </div>

                {/* Logistics Emergencies Section */}
                <div className="card">
                <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                    >
                        <span className="decorated-text">{t('LogisticETitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseFour"
                    className="collapse show"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/main/Logistic1.jpg" alt="Logistics Emergencies" className="img-fluid" ></img>
                        </div>
                        <div className="col-md-6">
                            <p>{t('LogisticEText')}</p>
                        </div>
                        {/* Add more content as needed */}
                    </div>
                    </div>
                </div>
                </div>
             
              {/* Hospitality Services Section */}
              <div className="card">
                <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                    >
                        <span className="decorated-text">{t('HospitalityTitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseFive"
                    className="collapse show"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/main/hospitality.jpg" alt="Hospitality Services" className="img-fluid" ></img>
                        </div>
                        <div className="col-md-6">
                            <p>{t('HospitalityText')}</p>
                        </div>
                        {/* Add more content as needed */}
                    </div>
                    </div>
                </div>
              </div>
              
              {/* Airport Transport Services Section */}
              <div className="card">
                <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                    >
                        <span className="decorated-text">{t('AirportTitle')}</span>
                    </button>
                    <button
                        className="btn btn-secondary float-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    </h2>
                </div>
                <div
                    id="collapseSix"
                    className="collapse show"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/main/Airport-Transport1.jpg" alt="Hospitality Services" className="img-fluid" ></img>
                        </div>
                        <div className="col-md-6">
                            <p>{t('AirportText')}</p>
                        </div>
                        {/* Add more content as needed */}
                    </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <br />
      </div>
    </div>
  );
};

export default About;
