import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BookingModal from './BookingModal';

const Tours = ({data}) => {

    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedTour, setSelectedTour] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredTours, setFilteredTours] = useState(data);
    const [selectedTourDetails, setSelectedTourDetails] = useState(null);
    const { t } = useTranslation();

    const selectedTourRef = useRef(null);
    // Get unique categories from the data
    const uniqueCategories = ['All', ...new Set(data.map(tour => tour.category))];

    // Filter tours based on the selected category
    useEffect(() => {
        const updatedFilteredTours = selectedCategory === 'All'
        ? data
        : data.filter(tour => tour.category === selectedCategory);

        setFilteredTours(updatedFilteredTours);
        // Reset selected tour when changing the category
        setSelectedTour(null);
        setSelectedTourDetails(null);
    }, [selectedCategory, data]);

    // Filter tour details based on the selected tour
    useEffect(() => {
        const updatedSelectedTourDetails = selectedTour
        ? data.find(tour => tour.name === selectedTour)
        : null;

        setSelectedTourDetails(updatedSelectedTourDetails);
    }, [selectedTour, data]);

    // Scroll to the selected tour when it changes
    useEffect(() => {
        if (selectedTourRef.current) {
        selectedTourRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [selectedTour]);

    return (
    <section className="section section-sm bg-default">
        <div className="container">
            <h2 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('OurTours')}</span></h2>
            <div className="row row-14 gutters-14">
                {/* Category filter dropdown */}
                <div className="col-md-4">
                    <div className="form-wrap">
                        <select
                        id="categoryFilter"
                        data-placeholder="Select a trip" 
	                    data-minimum-results-for-search="Infinity" 
                        className="form-input select-filter"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                        {uniqueCategories.map((category, index) => (
                            <option key={index} value={category}>{t(category)}</option>
                        ))}
                        </select>
                        
                    </div>
                </div>
                {/* Tour filter dropdown */}
            
                <div className="col-md-4">
                    <div className="form-wrap">
                        <label htmlFor="tourFilter" className="form-label"></label>
                        <select
                        id="tourFilter"
                        className="form-input select-filter"
                        data-placeholder="Select a trip" 
                        data-minimum-results-for-search="Infinity" 
                        value={selectedTour || ''}
                        onChange={(e) => setSelectedTour(e.target.value)}
                        >
                        <option value="">All Tours</option>
                        {filteredTours.map((tour, index) => (
                            <option key={index} value={tour.name}>{t(tour.name)}</option>
                        ))}
                        </select>
                    </div>
                </div>
            </div>
            
            {/* Display tour details */}
            {selectedTourDetails && (
            <div ref={selectedTourRef} className="row row-sm row-40 row-md-50">
                <div className="col-sm-6 col-md-12 wow fadeInRight">
                <article className="product-big">
                <div className="unit flex-column flex-md-row align-items-md-stretch">
                    <div className="unit-left"><a className="product-big-figure" onClick={() => { setIsModalOpen(true);}}><img src={selectedTourDetails.image} alt={selectedTourDetails.name} width="600" height="366"/></a></div>
                    <div className="unit-body">
                        <div className="product-big-body">
                        <h5 className="product-big-title"><a  onClick={() => { setIsModalOpen(true);}}>{t(selectedTourDetails.name)}</a></h5>
                        <div className="group-sm group-middle justify-content-start">
                            <div className="product-big-rating">
                                <span className="icon material-icons-star"></span>
                                <span className="icon material-icons-star"></span>
                                <span className="icon material-icons-star"></span>
                                <span className="icon material-icons-star"></span>
                                <span className="icon material-icons-star"></span>
                            </div>
                            <a className="product-big-reviews" href="#">{t('Location')}: {t(selectedTourDetails.category)}</a>
                        </div>
                        <p className="product-big-text">
                            {t('Tour')} <b>{t(selectedTourDetails.name)}</b> {t('is a tour that address the')} {t(selectedTourDetails.category)} {t('of Lebanon, its an')} {selectedTourDetails.time} {t('hours tour of enjoyment, prepared for')} {selectedTourDetails.pax} {t('Passengers')}.
                        </p>
                        
                        <button
                            onClick={() => { setIsModalOpen(true);}}
                            className="button button-black-outline button-ujarak"
                            >
                            <b>{t('BookNow')}</b>
                        </button>
                        
                        <a className="button button-black-outline button-ujarak" href={"https://wa.me/+96176672377?text=I'm%20interested%20in%20your%20tour:%20" + selectedTourDetails.name +"%20for%20"+selectedTourDetails.pax+"%20and%20price:%20"+selectedTourDetails.cost+"$"}>
                            <b>Whatsapp</b><img style={{height:20, width:25}} alt="Whatsapp" src="images/whatsapp/whatsapp.png"></img>
                        </a>
                        
                        <div className="product-big-price-wrap"><span className="product-big-price">${selectedTourDetails.cost}</span></div>
                        </div>
                    </div>
                    </div>
                </article>
                </div>
            </div>
            )}
            
            {/* Display a list of tours */}
            <div className="row mt-4">
                {filteredTours.map((tour, index) => (
                <div key={index} className="col-md-4 mb-4">
                    <div className="card shadow-sm" style={{ borderRadius: '15px', width: '300px' }}>
                    <img src={tour.image} className="card-img-top" alt={t(tour.name)}></img>
                    <div className="card-body" style={{backgroundColor:'#f7f7f7'}} >
                        <h5 className="card-title" style={{backgroundColor:'white'}}>{t(tour.name)}</h5>
                        <p className="card-text">{t('Location')}: {t(tour.category)}</p>
                        <p className="card-text">{t('Passengers')}: {tour.pax}</p>
                        <p className="card-text" style={{color:'green', backgroundColor:'white'}}><b>${tour.cost}</b></p>
                        <button
                        onClick={() => {
                            setSelectedTour(tour.name);
                            setSelectedTourDetails(tour);
                          }}
                          className="button button-black-outline button-ujarak"
                          style={{ backgroundColor: '#01b3a7', color: '#fff' }}
                        >
                        {t('ViewDetails')}
                        </button>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
        {/* Booking Modal */}
        <BookingModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            tourDetails={selectedTourDetails}
        />
    </section>

    );
}

export default Tours;
