import React, { useState, useEffect } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Modal from 'react-modal';

const TripBookingModal = ({ isOpen, onClose, tripDetails }) => {
  // Ensure that the modal is accessible by screen readers
  Modal.setAppElement('#root');

  // State to track form fields
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
    selectedTrip: tripDetails ? tripDetails.name : '',
    tripCost: tripDetails ? tripDetails.cost : '',
    selectedMembers: 1,
    date: '',
  });
  
  // Effect to update the form data when tripDetails change
  useEffect(() => {
    if (tripDetails) {
      setFormData({
        ...formData,
        selectedTrip: tripDetails.name,
        tripCost: tripDetails.cost,
      });
    }
  }, [tripDetails]);

  // Function to handle changes in form fields
  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const closeModal = () => {
    onClose();
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneNumberValid = (phoneNumber) => {
    try {
      // Attempt to parse the phone number using libphonenumber-js
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

      // Check if parsing was successful and if the phone number is valid
      return parsedPhoneNumber && parsedPhoneNumber.isValid();
    } catch (error) {
      console.log('Error parsing phone number:', error);
      return false;
    }
  };

// Function to handle form submission
const handleSubmit = async (e) => {
  e.preventDefault(); // Prevent default form submission

  // Prepare form data for submission
  const formBody = new URLSearchParams(formData);
  
  try {
    var n = document.getElementById('fullName').value;
    var em = document.getElementById('email').value;
    var p = document.getElementById('phoneNumber').value;
    var d = document.getElementById('date').value;
    var m = document.getElementById('message').value;
    let isValid = true;

    if (n === "" || d === "" || p === "" || em === "" || m === "" || n === null || d === null || em === null || p === null || m === null) {
      alert("Please fill all the fields");
      isValid=false;
    }

    var currentDate = new Date();   
    var inputDate = new Date(d);
    if(inputDate < currentDate)
    {
      alert('You have choosen a wrong date');
      isValid=false;
    }
    if (!isEmailValid(em)) {
      alert('Please enter a valid email address.');
      isValid = false;
    }

    if (!isPhoneNumberValid(p)) {
      alert('Please enter a valid phone number (e.g., +XXX-XX-XXX-XXX).');
      isValid = false;
    }

    if(isValid)
    {
      // Use fetch to send a POST request to the PHP script
      const response = await fetch('bat/rd-mailform.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });

    // Handle the response from the PHP script
    const data = await response.text();
   
     // Check for success (modify the condition as needed)
     if (data === 'MF000') {
      alert("Thank You for booking this trip, we will contact you soon.");

      // Reset the form after successful submission
      setFormData({
        fullName: '',
        selectedMembers: 1,
        email: '',
        phoneNumber: '',
        date: '',
        message: '',
        selectedTrip: tripDetails ? tripDetails.name : '',
        tripCost: tripDetails ? tripDetails.cost : '',
      });

      document.getElementById('fullName').value = "";
      document.getElementById('fullName').textContent = "";
      document.getElementById('email').value = "";
      document.getElementById('email').textContent = "";
      document.getElementById('phoneNumber').value = "";
      document.getElementById('phoneNumber').textContent = "";
      document.getElementById('date').value = "";
      document.getElementById('date').textContent = "";
      document.getElementById('message').value = "";
      document.getElementById('message').textContent = "";

      alert('Thank You for booking this trip, we will contact you soon.');
      closeModal(); // Close the modal after successful submission
    } else {
      alert("Email could not be sent. Please try again.");
    }
  }

  } catch (error) {
    console.error('Error:', error);
  }
};

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Trip Booking Modal"
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        content: { background: '#fff' },
        backgroundColor: 'white',
      }}
    >
      <div className="modal-header">
        <h2>Booking Details</h2>
        <button onClick={onClose} className="close-button">
          &times;
        </button>
      </div>

      <div className="modal-body">
        {tripDetails && (
          <>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 align="center">
                    Trip: <u>{tripDetails.name}</u>
                  </h3>
                  <br />
                  <h5 align="center">
                    {((tripDetails.name.split("/")[1])?("Trip " + tripDetails.name.split("/")[1] + " located in "):"Trasport from / to ")} <i>{tripDetails.category}</i>. The cost is ${tripDetails.cost}.
                    <br />
                    <br />
                    Please fill the details of your trip and book your trip right now!
                  </h5>
                </div>
              </div>
            </div>

            <form
              className="rd-form rd-form-variant-2 rd-mailform"
              data-form-output="form-output-global"
              data-form-type="contact"
              method="post"
              action="bat/rd-mailform.php"
              onSubmit={handleSubmit}
            >
              <div className="row row-14 gutters-14">
                <div className="col-md-4">
                  <div className="form-wrap">
                    <label>Full Name:</label>
                    <input
                      className="form-input"
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      data-constraints="@Required"
                      required
                      onChange={(e) => handleInputChange('fullName', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-wrap">
                    <label>Selected Trip:</label>
                    <input
                      className="form-input"
                      type="text"
                      value={formData.selectedTrip}
                      required
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-4" hidden>
                  <div className="form-wrap">
                    <label hidden>Cost:</label>
                    <input
                      className="form-input"
                      type="text"
                      value={"$"+formData.tripCost}
                      readOnly
                      required
                      hidden
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-wrap">
                    <label>Members:</label>
                    <select
                      className="form-input select-filter"
                      id="selectedMembers"
                      name="selectedMembers"
                      required
                      value={formData.selectedMembers}
                      onChange={(e) =>
                        handleInputChange('selectedMembers', e.target.value)
                      }
                    >
                      {[...Array(200).keys()].map((num) => (
                        <option key={num + 1} value={num + 1}>
                          {num + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-wrap">
                    <label>E-mail:</label>
                    <input
                      className="form-input"
                      type="email"
                      id="email"
                      name="email"
                      required
                      data-constraints="@Email @Required"
                      value={formData.email}
                      onChange={(e) => handleInputChange('email', e.target.value)}
                      
                    />
                  </div>
                </div>
                <div className="col-md-4">
                <div className="form-wrap">
                  <label>Phone:</label>
                  <input
                    className="form-input"
                    type="tel" // Use type="tel" for telephone numbers
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter phone number (e.g., +XXX-XXX-XXXX)"
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      handleInputChange('phoneNumber', e.target.value)
                    }
                    required // Add the required attribute if the phone number is mandatory
                  />
                  <small>Format: (country code then Phone Number) </small>
                </div>
                </div>
                <div className="col-md-4">
                  <label>Date:</label>
                  <div className="form-wrap">
                    <input
                      className="form-input"
                      type="datetime-local"
                      id="date"
                      name="date"
                      required
                      value={formData.date}
                      onChange={(e) => handleInputChange('date', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label>Message:</label>
                    <textarea
                      className="form-input textarea-lg"
                      id="message"
                      name="message"
                      required
                      value={formData.message}
                      onChange={(e) =>
                        handleInputChange('message', e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <div>
                  <button
                    className="button button-primary button-pipaluk"
                    type="submit"
                  >
                    Book This Trip
                  </button>
                </div>
              </div>
            </form>

            <label>
              <b>
                Note: The specified cost is for the entire trip.
              </b>
            </label>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TripBookingModal;
