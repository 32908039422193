import React, { useState } from 'react';
import BookingModal from './BookingModal';
import { useTranslation } from 'react-i18next';

const HotTours = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const { t } = useTranslation();
  // Function to handle opening the modal with tour details
  const handleBuyTour = (tourDetails) => {
    setSelectedTour({
      ...tourDetails,
      // Add other relevant tour details here
    });
    setIsModalOpen(true);
  };

  return (
    <>
      <section className="section section-sm bg-default">
        <div className="container">
          <h3 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('HotTours')}</span></h3>
          <div className="row row-sm row-40 row-md-50">
            <div className="col-sm-6 col-md-12 wow fadeInRight">
              <article className="product-big">
                <div className="unit flex-column flex-md-row align-items-md-stretch">
                  <div className="unit-left">
                    <a className="product-big-figure" href=" ">
                      <img src="images/tours/Tripoly1.jpeg" alt="" width="600" height="366" />
                    </a>
                  </div>
                  <div className="unit-body">
                    <div className="product-big-body">
                      <h5 className="product-big-title"><a href="/tours">{t('Tour1Name')}</a></h5>
                      <div className="group-sm group-middle justify-content-start">
                        <div className="product-big-rating">
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                        </div>
                        <a className="product-big-reviews" href=" "> {t('Location')}: {t('North')}</a>
                      </div>
                      <p className="product-big-text">
                        {t('Tour1Details')}
                      </p>
                      <a
                        className="button button-black-outline button-ujarak"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleBuyTour({
                            image: "/images/tours/Tripoly1.jpeg",
                            name: 'Beirut/Tripoli/El-Donnieh',
                            pax: '4--6',
                            cost: '150',
                            category: 'North'
                            // Add other relevant tour details here
                          });
                        }}
                        >
                        {t('BuyTour')}
                    </a>
                      <div className="product-big-price-wrap">
                        <span className="product-big-price">$150</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="product-big">
                <div className="unit flex-column flex-md-row align-items-md-stretch">
                  <div className="unit-left">
                    <a className="product-big-figure" href=" ">
                      <img src="images/tours/Saida.jpg" alt="" width="600" height="366" />
                    </a>
                  </div>
                  <div className="unit-body">
                    <div className="product-big-body">
                      <h5 className="product-big-title"><a href="/tours">{t('Tour2Name')}</a></h5>
                      <div className="group-sm group-middle justify-content-start">
                        <div className="product-big-rating">
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                          <span className="icon material-icons-star"></span>
                        </div>
                        <a className="product-big-reviews" href=" "> {t('Location')}: {t('South')}</a>
                      </div>
                      <p className="product-big-text">
                      {t('Tour2Details')}
                      </p>
                      <a
                        className="button button-black-outline button-ujarak"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleBuyTour({
                            image: "images/tours/Saida.jpg",
                            name: 'Beirut/saida/Tyre 2',
                            pax: '4--6',
                            cost: '160',
                            category: 'South'
                            // Add other relevant tour details here
                          });
                        }}
                        >
                        {t('BuyTour')}
                    </a>
                      <div className="product-big-price-wrap">
                        <span className="product-big-price">$160</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            {/* Repeat the structure for other tours */}

          </div>
        </div>
      </section>

      {/* Render the BookingModal with the modal state and tour details */}
      <BookingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} tourDetails={selectedTour} />
    </>
  );
};

export default HotTours;
