import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

const MainMenu = () => {
  const { t } = useTranslation();
  return (
        <>
        <section>
          <Navbar bg="#162E44" variant="dark" expand="lg" fixed="top" style={{backgroundColor:"#162E44", height:"80px"}}>
            <Container>
              <Navbar.Brand as={Link} to="/home" className="brand">
              <img src="images/logo2.png" alt="logo" height="80px" width="80px"></img>
                
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" style={{position:'relative', left:'1px', right:'1px', margin:'0'}}/>
              <Navbar.Collapse id="basic-navbar-nav" style={{backgroundColor:"#162E44", }}>
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/home" className="nav-link" activeClassName="active">
                    <b>{t('Home')}</b>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/tours" className="nav-link" activeClassName="active">
                  <b>{t('Tours')}</b>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/trips" className="nav-link" activeClassName="active">
                  <b>{t('Trips')}</b>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/about" className="nav-link" activeClassName="active">
                  <b>{t('About')}</b>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/policy" className="nav-link" activeClassName="active">
                  <b>{t('Privacy and Policy')}</b>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/contact" className="nav-link" activeClassName="active">
                  <b>{t('Contact Us')}</b>
                  </Nav.Link>
                </Nav>
                <Nav>
                  <ul className="list-inline list-inline-md rd-navbar-corporate-list-social">
                      <li><Link className="icon fa fa-facebook" to="https://www.facebook.com/profile.php?id=100094461385540&mibextid=zLoPMf"></Link></li>
                      <li><Link className="icon fa fa-instagram" to="https://www.instagram.com/touralloverlebanon"></Link></li>
                      <li><LanguageSwitcher/></li>
                  </ul>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </section>
        <br/>
        </>
    );
}

export default MainMenu;
