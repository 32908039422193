import React from 'react';
import { useTranslation } from 'react-i18next';

const Payments = () => {
  const { t } = useTranslation();
  const containerStyle = {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#f8f8f8',
  };

  const contentStyle = {
    fontSize: '16px',
    margin: '0 0 20px',
  };

  const paymentOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  };

  const imageStyle = {
    width: '50px',
    height: '50px',
    margin: '0 10px',
    borderRadius: '50%',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={containerStyle}>
      <p style={contentStyle}>
        {t('PaymentText')}
      </p>
      <div style={paymentOptionStyle}>
        <img
          src="images/Payments/WishMoney.jpeg"
          alt="Wish Money"
          style={imageStyle}
        />
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Wish Money</span>
        <img
          src="images/Payments/Visa.jpeg"
          alt="Visa"
          style={imageStyle}
        />
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Visa</span>
      </div>
    </div>
  );
};

export default Payments;
