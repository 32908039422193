import React from 'react';
import { useTranslation } from 'react-i18next';

const NewHorizons = () => {
  const { t } = useTranslation();  
  return (
        <section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp"><img src="images/Horizons/Horizon.jpg" alt="" width="556" height="382"/>
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>{t('NewHorizonsTitle')}</h3>
                
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  
                  <ul class="nav nav-tabs">
                    <li class="nav-item" role="presentation"><a class="nav-link active" href="#tabs-7-1" data-toggle="tab">{t('NewHorizonsAboutUsTab1Title')}</a></li>
                    <li class="nav-item" role="presentation"><a class="nav-link" href="#tabs-7-2" data-toggle="tab">{t('NewHorizonsAboutUsTab2Title')}</a></li>
                    <li class="nav-item" role="presentation"><a class="nav-link" href="#tabs-7-3" data-toggle="tab">{t('NewHorizonsAboutUsTab3Title')}</a></li>
                  </ul>
                  
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="tabs-7-1">
                      <p>
                       {t('NewHorizonsAboutUsTab1Text')}
                      </p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" href="/contact">{t('TouchButton')}</a><a class="button button-black-outline button-md" href="/about">{t('ReadMoreButon')}</a></div>
                    </div>
                    <div class="tab-pane fade" id="tabs-7-2">
                      <p>{t('NewHorizonsAboutUsTab2Text')}</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" href="/contact">{t('TouchButton')}</a><a class="button button-black-outline button-md" href="/about">{t('ReadMoreButon')}</a></div>
                    </div>
                    <div class="tab-pane fade" id="tabs-7-3">
                      <p>{t('NewHorizonsAboutUsTab3Text')}</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" href="/contact">{t('TouchButton')}</a><a class="button button-black-outline button-md" href="/about">{t('ReadMoreButon')}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default NewHorizons;
