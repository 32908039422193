import React from 'react';
import MainMenu from '../Components/MainMenu';
import { useLocation } from 'react-router-dom';
const BookingPage = () => {
    const location = useLocation();
    const { state } = location || {};
    // Check if state is not null or undefined
    if (!state) {
        // You can handle the case when state is not available
        return (
            <div className="container mt-4">
                <p>No booking details available.</p>
            </div>
        );
    }
    return (
        <>
        <header class="section page-header">
            <MainMenu/>
            <br/><br/>
        </header>
        <div className="container mt-4">
            <h2>Booking Details</h2>
            <div className="card">
                <img src={state.image} className="card-img-top" alt={state.name} />
                <div className="card-body">
                    <h5 className="card-title">{state.name}</h5>
                    <p className="card-text">Location: {state.category}</p>
                    <p className="card-text">Pax: {state.pax}</p>
                    <p className="card-text">Time: {state.time} hours</p>
                    <p className="card-text">Cost: ${state.cost}</p>
                </div>
            </div>
        </div>
        </>
    );
}

export default BookingPage;
