import React from 'react';
import { useTranslation } from 'react-i18next';

const TopHeader = () => {
    const { t } = useTranslation();
    return (
            <div className="container">
              <br/>
            <div className="row">
              <div className="col-sm-4">
              <img src="images/logo.jpeg" alt="Tours all over lebanon" width="300" height="40" />
              </div>
              <div className="col-sm-4">
              <div className="unit unit-spacing-xs">
                    <div className="unit-left"><span className="icon fa fa-clock-o"></span></div>
                    <div className="unit-body">
                      <p> {t('Hours')} 24 / 7 {t('Days')} {t('support availability')}</p>
                    </div>
                  </div>
              </div>
              <div className="col-sm-4">
                <div className="unit unit-spacing-xs">
                    <div className="unit-left"><span className="icon fa fa-phone"></span></div>
                    <div className="unit-body">
                      <a className="link-phone" href="tel:+96171406376">+961 71 406 376</a><span> / </span>
                      <a className="link-phone" href="tel:+96176672377">+961 76 672 377</a>
                    </div>
                  </div>
              </div>
            </div>
            <br/>
          </div> 
    );
}

export default TopHeader;
