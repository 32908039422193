import React from 'react';
import BoxCategories from '../Components/BoxCategories';
import NewHorizons from '../Components/NewHorizons';
import OurServices from '../Components/OurServices';
import Subscribe from '../Components/Subscribe';
import Tours from '../Components/HotTours';
import SliderComponent from '../Components/SwiperComponent';
import MainMenu from '../Components/MainMenu';
import TopHeader from '../Components/TopHeader';
import Payments from '../Components/Payments';

const Index = () => {
    return (
        <div>
            <header class="section page-header">
                <MainMenu/>
                <TopHeader/>
                <SliderComponent swiperID="Slider1"/>
            </header>
            
            <BoxCategories/>
             <NewHorizons/>
             <OurServices/>
            <Tours/>
            <Subscribe />
            <Payments />
        </div>
    );
}

export default Index;
