import React from 'react';
import { useTranslation } from 'react-i18next';

const Subscribe = () => {
  const { t } = useTranslation();
    return (
      <section className="section bg-default text-center offset-top-50">
      <div className="parallax-container" style={{ backgroundImage: 'url(images/main/cars.jpg)' }} data-parallax-img="images/main/cars.jpg">
        <div className="parallax-content section-xl section-inset-custom-1 context-dark bg-overlay-2-21">
          <div className="container">
            <h2 className="heading-2 oh font-weight-normal wow slideInDown">
              <span className="d-block font-weight-semi-bold">{t('SubscribeTitle1')}</span>
              <span className="d-block font-weight-light">{t('SubscribeTitle2')}</span>
            </h2>
            <p className="text-width-medium text-spacing-75 wow fadeInLeft" data-wow-delay=".1s">
              {t('SubscribeText')}
            </p>
            <a className="button button-secondary button-pipaluk" href="/tours">{t('Subscribebutton')}</a>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Subscribe;
