import React from 'react';
import { useTranslation } from 'react-i18next';

const BoxCategories = () => {
  const { t } = useTranslation();
    return (
        <section class="section section-lg section-top-1 bg-gray-4">
        <div class="container offset-negative-1">
          <div class="box-categories cta-box-wrap">
            <div class="box-categories-content">
              <div class="row justify-content-center">
                <div class="col-md-4 wow fadeInDown col-9" data-wow-delay=".2s">
                  <ul class="list-marked-2 box-categories-list">
                    <li><a href="/tours"><img src="images/boxes/city.jpg" alt="" width="368" height="420"/></a>
                      <h5 class="box-categories-title">{t('Box1')}</h5>
                    </li>
                  </ul>
                </div>
                <div class="col-md-4 wow fadeInDown col-9" data-wow-delay=".2s">
                  <ul class="list-marked-2 box-categories-list">
                    <li><a href="/trips"><img src="images/boxes/mountain.jpg" alt="" width="368" height="420"/></a>
                      <h5 class="box-categories-title">{t('Box2')}</h5>
                    </li>
                  </ul>
                </div>
                <div class="col-md-4 wow fadeInDown col-9" data-wow-delay=".2s">
                  <ul class="list-marked-2 box-categories-list">
                    <li><a href="/about"><img src="images/boxes/sea.jpg" alt="" width="368" height="420"/></a>
                      <h5 class="box-categories-title">{t('Box3')}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div><a class="link-classic wow fadeInUp" href="/tours">{t('BoxLink')}<span></span></a>
        </div>
      </section>
    );
}

export default BoxCategories;
