import React, { useState } from 'react';
import TripBookingModal from './TripBookingModal';
import { useTranslation } from 'react-i18next';

const Trips = ({ data }) => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const { t } = useTranslation();

    const uniqueCategories = [...new Set(data.map(trip => trip.category))];

    const handleOpenModal = (trip) => {
        console.log('Opening modal for trip:', trip);
        setSelectedTrip(trip);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Filter trips based on the selected category
    const filteredTrips = selectedCategory === 'All'
        ? data
        : data.filter(trip => trip.category === selectedCategory);

    return (
        <>
            <section>
                <div className="container mt-4">
                    <h2 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('TripPriceList')}</span></h2>
                    
                    {/* Category filter dropdown with styling */}
                    <div className="mb-3">
                        <select
                            id="categoryFilter"
                            className="form-input select-filter form-control-has-validation form-control-last-child"
                            name="category" data-placeholder="Select a county" data-minimum-results-for-search="Infinity"
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                            <option value="All">All Locations</option>
                            {uniqueCategories.map((category, index) => (
                                <option key={index} value={category}>{t(category)}</option>
                            ))}
                        </select>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: '50%' }}>{t('Trip')}</th>
                                    <th style={{ width: '30%' }}>{t('Location')}</th>
                                    <th style={{ width: '10%' }}>{t('Price')}</th>
                                    <th style={{ width: '10%' }}>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="16" height="16" 
                                        fill="currentColor" 
                                        class="bi bi-whatsapp" 
                                        viewBox="0 0 16 16">
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                                    </svg>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTrips.map((trip, index) => (
                                    <tr key={index}>
                                        <td>
                                            <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default anchor behavior
                                                handleOpenModal(trip);
                                            }}
                                            >
                                            {t(trip.name)}
                                            </a>
                                        </td>
                                        <td>{t(trip.category)}</td>
                                        <td>${trip.cost}</td>
                                        <td>
                                             <a href={"https://wa.me/+96176672377?text=I'm%20interested%20in%20your%20trip:%20" + trip.name +"%20and%20price:%20"+trip.cost+"$"}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
</svg>
                                             </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            {/* Trip Booking Modal */}
            <TripBookingModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tripDetails={selectedTrip}
            />
        </>
    );
};

export default Trips;
