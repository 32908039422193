import React from 'react';
import MainMenu from '../Components/MainMenu';

const NoPage = () => {
    return (
        <>
        <header class="section page-header">
            <MainMenu/>
            <br/><br/>
        </header>
        <div>
            <h2>Error 404: Not found</h2>
        </div>
        </>
    );
}

export default NoPage;
