import React from 'react';
import ContactUs from '../Components/Contact';
import MainMenu from '../Components/MainMenu';

const Contact = () => {
    return (
        <>
        <header class="section page-header">
            <MainMenu/>
            <br/><br/>
        </header>
        <div>
        <br/>
         <ContactUs/>
        </div>
        </>
        
    );
}

export default Contact;
