import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const clearForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneNumberValid = (phoneNumber) => {
    try {
      // Attempt to parse the phone number using libphonenumber-js
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

      // Check if parsing was successful and if the phone number is valid
      return parsedPhoneNumber && parsedPhoneNumber.isValid();
    } catch (error) {
      console.log('Error parsing phone number:', error);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Prepare form data for submission
    const formBody = new URLSearchParams(formData);

    var n = document.getElementById('name').value;
    var em = document.getElementById('email').value;
    var p = document.getElementById('phone').value;
    var m = document.getElementById('message').value;
    let isValid = true;

    if (n === '' || em === '' || p === '' || m === '' || n === null || em === null || p === null || m === null) {
      alert('Please fill all the fields');
      isValid=false;
    }
    
    if (!isEmailValid(em)) {
      alert('Please enter a valid email address.');
      isValid = false;
    }

    if (!isPhoneNumberValid(p)) {
      alert('Please enter a valid phone number (e.g., +XXX-XX-XXX-XXX).');
      isValid = false;
    }

    if(isValid)
    {
    // Use fetch to send a POST request to the PHP script
    fetch('bat/rd-mailform.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => response.text())
      .then((data) => {
        // Handle the response from the PHP script
        if (data === 'MF000') {
          console.log('Email sent successfully');
          // Reset the form after successful submission if needed
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
          clearForm();
          document.getElementById('name').value = '';
          document.getElementById('email').value = '';
          document.getElementById('phone').value = '';
          document.getElementById('message').value = '';
          document.getElementById('name').textContent = '';
          document.getElementById('email').textContent = '';
          document.getElementById('phone').textContent = '';
          document.getElementById('message').textContent = '';
        
          if (window.confirm('Thank you for contacting us. Press OK to go back to the homepage.')) {
            // Redirect to the homepage
            window.location.href = '/';
          }
          
        } else {
          console.error('Email could not be sent. Please try again.');
        }     
      })
      .catch((error) => {
        console.error('Error:', error);
        clearForm();
      });
    }
  };

  return (
    <>
    <div>
      <br/>
      <h2 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('Contact Us')}</span></h2>
      <br/>
    </div>

    <img src="images/Contact/Contact.jpeg" alt="Contact us"></img>
    <section className="section section-sm section-last bg-default text-left">
      <div className="container">
        <article className="title-classic">
          <div className="title-classic-title">
            <h3>{t('TouchButton')}</h3>
          </div>
          <div className="title-classic-text">
            <p>{t('Question')}</p>
          </div>
        </article>
        <form
          className="rd-form rd-form-variant-2 rd-mailform"
          data-form-output="form-output-global"
          data-form-type="contact"
          onSubmit={handleSubmit}
        >
          <div className="row row-14 gutters-14">
            <div className="col-md-4">
              <div className="form-wrap">
                <label>{t('FullName')}</label>
                <input
                  className="form-input"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-wrap">
                <label>{t('Email')}</label>
                <input
                  className="form-input"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-wrap">
                <label>{t('Phone')}</label>
                <input
                  className="form-input"
                  type="tel"
                  placeholder="Enter phone number (e.g., +XXX-XXX-XXXX)"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-wrap">
                <label>{t('Message')}</label>
                <textarea
                  className="form-input textarea-lg"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <button className="button button-primary button-pipaluk" type="submit">
            <b>{t('SendMessage')}</b>
          </button>
        </form>
      </div>
    </section>
    </>
  );
};

export default Contact;
