import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Index from "./Pages/index";
import About from "./Pages/about";
import Contact from "./Pages/contact";
import Policy from "./Pages/policy";
import Tours from "./Pages/toursPage";
import Trips from "./Pages/trips";
import BookingPage from "./Pages/BookingPage";
import NoPage from "./Pages/noPage";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Your i18next configuration


function App() {
  return (
    <I18nextProvider i18n={i18n}>
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Index/>}></Route>
          <Route path="/home" element={<Index/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/policy" element={<Policy/>}></Route>
          <Route path="/tours" element={<Tours/>}></Route>
          <Route path="/trips" element={<Trips/>}></Route>

          <Route path="/booking" element={<BookingPage/>}></Route>

          <Route path="*" element={<NoPage/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
    </I18nextProvider>
  );
}

export default App;
