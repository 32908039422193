import React from 'react';
import MainMenu from '../Components/MainMenu';
import { useTranslation } from 'react-i18next';

const Policy = () => {
    const { t } = useTranslation();
    return (
        <>
        <header class="section page-header">
            <MainMenu/>
            <br/><br/><br/><br/>
        </header>
        <div>
        <h2 className="oh-desktop"><span className="d-inline-block wow slideInDown">{t('Privacy and Policy')}</span></h2>
            <br/>
        </div>
        <div>
            <img src="images/Privacy/Privacy.jpeg" alt="Privacy & Policy"></img>
            <br/><br/>
            <h4>
            <p>{t('PrivacyText')}</p>
            </h4>
            <br/><br/>
            <h4>{t('PolicyContact')} <a href="/contact"><u>{t('Contact Us')}</u></a></h4>
            <br/><br/>
        </div>
        </>
    );
}

export default Policy;
