import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const storedActiveLanguage = localStorage.getItem('activeLanguage');
  const storedButtonColors = JSON.parse(localStorage.getItem('buttonColors'));

  const defaultActiveLanguage = 'en';
  const defaultButtonColors = {
    en: { backgroundColor: '#04AA6D' },
    ar: { backgroundColor: '#162E44' },
  };

  const [activeLanguage, setActiveLanguage] = useState(
    storedActiveLanguage || defaultActiveLanguage
  );
  const [buttonColors, setButtonColors] = useState(
    storedButtonColors || defaultButtonColors
  );

  useEffect(() => {
    localStorage.setItem('activeLanguage', activeLanguage);
    localStorage.setItem('buttonColors', JSON.stringify(buttonColors));
  }, [activeLanguage, buttonColors]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);

    setButtonColors((prevColors) => ({
      ...prevColors,
      [lng]: { backgroundColor: '#04AA6D' },
      [Object.keys(prevColors).find((lang) => lang !== lng)]: {
        backgroundColor: '#162E44',
      },
    }));
  };

  return (
    <div>
      <button
        onClick={() => changeLanguage('en')}
        style={{
          ...buttonColors.en,
          fontSize: '10px',
          textAlign: 'center',
          color: 'white',
          padding: '15px 15px',
        }}
      >
        <b><i>En</i></b>
      </button>
      <button
        onClick={() => changeLanguage('ar')}
        style={{
          ...buttonColors.ar,
          fontSize: '10px',
          textAlign: 'center',
          color: 'white',
          padding: '15px 15px',
        }}
      >
        <b><i>Ar</i></b>
      </button>
    </div>
  );
};

export default LanguageSwitcher;
