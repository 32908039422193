import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './Data/en.json';
import arTranslation from './Data/ar.json';

const resources = {
  en: { translation: enTranslation },
  ar: { translation: arTranslation },
};

const defaultLanguage = 'en';
const storedLanguage = localStorage.getItem('language');

i18n
.use(initReactI18next)
.init({
  resources,
  lng: storedLanguage || defaultLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

// Listen for language changes and save to localStorage
i18n.on('languageChanged', (lng) => {
localStorage.setItem('language', lng);
});

export default i18n;