import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SwiperComponent = ({ swiperId }) => {

  const { t } = useTranslation();

  useEffect(() => {
    // Include Swiper JS and CSS directly
    const swiperScript = document.createElement('script');
    swiperScript.src = `${process.env.PUBLIC_URL}/js/script.js`;
    swiperScript.async = true;

    swiperScript.onerror = () => {
      console.error('Error loading Swiper JS file');
    };

    document.body.appendChild(swiperScript);

    const swiperStyle = document.createElement('link');
    swiperStyle.rel = 'stylesheet';
    swiperStyle.href = `${process.env.PUBLIC_URL}/css/style.css`;

    swiperStyle.onerror = () => {
      console.error('Error loading Swiper CSS file');
    };

    document.head.appendChild(swiperStyle);

    // Initialize Swiper after scripts are loaded
    swiperScript.onload = () => {
      const mySwiper = new window.Swiper(`#${swiperId}`, {
        loop: true,
        autoplay: {
          delay: 5000,
        },
        simulateTouch: true,
        direction: 'vertical',
        pagination: {
          el: `#${swiperId} .swiper-pagination`,
          clickable: true,
        },
      });
    };

    // Clean up script and style elements when the component is unmounted
    return () => {
      document.body.removeChild(swiperScript);
      document.head.removeChild(swiperStyle);
    };
  }, [swiperId]);

  return (
    <div className={`swiper-container swiper-slider swiper-slider-corporate swiper-pagination-style-2`} id={swiperId}>
      <div className="swiper-wrapper text-left">
        <div className="swiper-slide context-dark" data-slide-bg="images/main/raoushe.jpg">
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <h6 className="text-uppercase" data-caption-animate="fadeInRight" data-caption-delay="0">
                    {t('slider1-small')}
                  </h6>
                  <h2 className="oh font-weight-light" data-caption-animate="slideInUp" data-caption-delay="100">
                    <span className="font-weight-bold">{t('slider1-Big')}</span>
                  </h2>
                  <a
                    className="button button-default-outline button-ujarak"
                    href="/contact"
                    data-caption-animate="fadeInLeft"
                    data-caption-delay="0"
                  >
                    {t('TouchButton')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide context-dark" data-slide-bg="images/main/taxi1.jpg">
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <h6 className="text-uppercase" data-caption-animate="fadeInRight" data-caption-delay="0">
                  {t('slider2-small')}
                  </h6>
                  <h2 className="oh font-weight-light" data-caption-animate="slideInUp" data-caption-delay="100">
                    <span className="font-weight-bold">{t('slider2-Big')}</span>
                  </h2>
                  <a
                    className="button button-default-outline button-ujarak"
                    href="/contact"
                    data-caption-animate="fadeInLeft"
                    data-caption-delay="0"
                  >
                    {t('TouchButton')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide context-dark" data-slide-bg="images/main/taxi2.jpg">
          <div className="swiper-slide-caption section-md">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <h6 className="text-uppercase" data-caption-animate="fadeInRight" data-caption-delay="0">
                  {t('slider3-small')}
                  </h6>
                  <h2 className="oh font-weight-light" data-caption-animate="slideInUp" data-caption-delay="100">
                    <span className="font-weight-bold">{t('slider3-Big')}</span>
                  </h2>
                  <a
                    className="button button-default-outline button-ujarak"
                    href="/contact"
                    data-caption-animate="fadeInLeft"
                    data-caption-delay="0"
                  >
                    {t('TouchButton')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Swiper Pagination */}
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default SwiperComponent;
