import React from 'react';
import CTrips from '../Components/Trips';
import data from '../Data/tripsdata.json'
import MainMenu from '../Components/MainMenu';

const Trips = () => {
    return (
        <>
        <header class="section page-header">
            <MainMenu/>
            <br/><br/>
        </header>
        <br/>
        <div>
            <CTrips data={data}/>
        </div>
        </>
    );
}

export default Trips;
